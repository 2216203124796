import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils/breakpoints';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';
import { root as btn, tiny } from 'modules/app/styles/IconButton.styles';

export const root = css`
  margin-top: ${spacing(12)};

  @media ${breakpoints.medium} {
    margin-top: 0;
  }
`;

export const inputWrapper = css`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(221, 225, 227, 0.1);
  border-radius: ${spacing(3)};
  transition: border 0.2s;
  display: flex;
  align-items: center;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid rgba(221, 225, 227, 0.3);
  }
`;

export const inputError = css`
  ${inputWrapper};
  border-color: hsl(var(--color-warning-6));

  &:hover,
  &:focus,
  &:active {
    border-color: hsl(var(--color-warning-6));
  }
`;

export const input = css`
  flex: 1;
  height: 100%;
  outline: none;
  border: none;
  background: transparent;
  border-radius: ${spacing(3)};
  padding: ${spacing(8)} ${spacing(8)} ${spacing(8)} ${spacing(16)};
  color: hsl(var(--color-textLight-7));
`;

export const button = css`
  ${btn};
  ${tiny};
  border-radius: 0;
  background: transparent;
  outline: none;

  svg {
    width: ${spacing(16)};
    height: ${spacing(16)};
  }
`;

export const error = css`
  > span {
    color: hsl(var(--color-warning-6));
  }
`;

export const label = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: hsl(var(--color-grayscale-6));
  margin-bottom: ${spacing(4)};

  > label {
    ${font.size.small};
  }

  > span {
    ${font.size.tiny};
  }
`;

export const mobile = css`
  @media ${breakpoints.medium} {
    display: none;
  }
`;

export const bottomLabel = css`
  margin-top: ${spacing(4)};
  ${font.size.tiny};
  color: hsl(var(--color-textLight-7));
  ${mobile};
`;

export const bottomLabelError = css`
  ${bottomLabel};
  color: hsl(var(--color-warning-6));
`;

export const attachments = css`
  max-width: 100%;
  max-height: ${spacing(200)};
  overflow-y: auto;
  overflow-x: hidden;
`;
